export const MESSAGES = {
  DEFAULT_ERROR: 'Something went wrong. Please try again later.',
};

export const RESOURCE_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  READY: 'ready',
  ERROR: 'error',
  UPDATED: 'updated',
};

export const DATE_TYPE = {
  RELATIVE: 'relative',
  MEDIUM: 'medium',
  LONG: 'long',
};

export const ALARM_NAME = {
  OVERFILL: 'overfill',
  LOW_1: 'low_1',
  LOW_2: 'low_2',
  LOW_BATTERY: 'low_battery',
  LATE_REPORTS: 'late_reports',
  EVERY_READING: 'every_reading',
};

export const READING_TYPE = {
  ALL: 'all',
  COMMISSION: 'commission',
  MEASUREMENT: 'measurement',
};

export const TRANSMITION_INTERVAL = {
  hourly: 'hourly',
  timesPerDay: 'times_per_day',
  daily: 'daily',
  monthly: 'monthly',
};

export const INVALID_DATE_STRING = 'Invalid Date';

export const ROLES = {
  xactAdmin: 'xact_admin',
  companyAdmin: 'company_admin',
  zoneManager: 'zone_manager',
  commonUser: 'common_user',
};

export const TIMES_PER_DAY = 'times_per_day';

export const TOAST_STATUS = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const SENSOR_TYPES = {
  GAUGE_READER: 'gauge_reader',
  SUBMERSIBLE: 'submersible',
  PRESSURE: 'pressure',
  RADAR: 'radar',
};

export const GAUGE_TYPES = {
  ROCHESTER_HORIZONTAL: 'rochester_horiz',
  ROCHESTER_VERTICAL: 'rochester_vert',
};

export const COMMON_USER_VISIBILITY = {
  COMPANY: 'company_visibility',
  ZONES: 'zone_visibility',
  TANKS: 'tank_visibility',
};

export const MEASURE_UNITS = {
  IMPERIAL: 'imperial',
  METRIC: 'metric',
};

export const MONITOR_TYPES = {
  GLOBALSTAR: 'GlobalstarMonitor',
  CATCON: 'CatconMonitor',
};

export const TANK_MONITOR_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  IN_TRANSIT: 'in_transit',
  RMA: 'rma',
};

export const ORDER_DIRECTION = {
  ASCENDENT: 'asc',
  DESCENDENT: 'desc',
};

export const FILL_EVENT_REPORT_OPTIONS = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  NONE: 'none',
};

export const PAGINATION_VALUES = ['10', '20', '50', '100'];

export const DEFAULT_PAGINATION_VALUE = '100';

export const ALARM_SCHEDULE_OPTIONS = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  NONE: 'none',
};

export const THEME = {
  KBC: 'KBC',
  XACT: 'xact',
};

export const KBC_URL = 'https://rtm.kbc.global';

export const DEFAULT_BRANDING = {
  favicon: 'favicon',
  title: 'Xact Portal',
  helpWidgetUrl:
    'https://d29l98y0pmei9d.cloudfront.net/js/widget.min.js?k=Y2xpZW50SWQ9NjA3MiZob3N0TmFtZT14YWN0LnN1cHBvcnRoZXJvLmlv',
};

export const KBC_BRANDING = {
  favicon: 'kbc_favicon',
  title: 'KBC RTM Portal',
  helpWidgetUrl:
    'https://d29l98y0pmei9d.cloudfront.net/js/widget.min.js?k=Y2xpZW50SWQ9NjE0NCZob3N0TmFtZT1rYmMuc3VwcG9ydGhlcm8uaW8=',
};
